<template>
  <div>
    <b-card
      no-body
      class=""
    >
      <div class="">
        <b-row class="p-1">
          <!-- v-if="roles.isAdmin" -->
          <div class="d-flex flex-column ml-1">
            <label
              class="saj-text"
              style="margin-left: 1px;"
            >{{ $t("Cycle") }}:</label>
            <!-- <div class="bg-danger"> -->
            <Datepicker
              id="input-id"
              v-model="selectedCycle"
              :value="customFormatter"
              :format="customFormatter"
              minimum-view="year"
              name="datepicker"
              input-class="year-custom"
              class="show-only-year"
            />
            <!-- </div> -->
          </div>
          <div
            v-if="selectedCompany !== 0"
            class="d-flex flex-column ml-1"
          >
            <label class="saj-text">{{ $t("Filter by") }}:</label>
            <div class="row">
              <b-form-select
                v-model="filterBy"
                :options="roles.isHOD ? listFilterBy.filter(x => x.value !== 'Department') : listFilterBy"
                size="sm"
                class="saj-text"
                style="width: 190px; margin-left: 14px; height: 34px"
              />
            </div>

          </div>
        </b-row>
        <b-row class="p-1 d-flex justify-content-end align-items-end">
          <b-button
            md="4"
            variant="primary"
            class="saj-button mr-1"
            block
            style="width: 120px; height: 45px;"
            @click="filter(), graphData = [], refreshGraph += 1, show = false, filterBy !== null ? isOverall = false : isOverall = true, search = true"
          >
            <!-- <feather-icon icon="SearchIcon" /> -->
            <span
              class=""
              style=""
            > {{ $t("Filter") }} </span>
          </b-button>
          <b-button
            md="4"
            variant="primary"
            class="saj-button mr-1"
            block
            style="width: 120px; height: 45px;"
            @click="clearButton()"
          >

            <span
              class=""
              style=""
            > {{ $t("Clear") }} </span>
          </b-button>
        </b-row>
      </div>
      <!-- {{ graphData }} -->
    </b-card>
    <b-overlay
      :show="search"
      rounded="sm"
      class="mt-3"
    >
      <template #overlay>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style="margin-top: 30vh;"
        >
          <b-spinner
            variant="primary"
            label="Spinning"
          />
          <p class="mt-1">
            {{ $t('Fetching Data') }}...
          </p>
        </div>

      </template>
      <div v-if="show">
        <!-- <div
          v-if="isOverall"
          :key="refreshGraph"
          class=""
        >
          <overall-graph
            :graph="graphValue"
            :category-type="catType"
          />
        </div> -->
        <div
          :key="refreshGraph"
          class=""
        >
          <b-tabs content-class="mt-3">
            <b-tab
              :title="$t('Bar Graph')"
              active
            >
              <bar-graph
                :graph="graphValue"
                :category-type="catType"
              />
            </b-tab>
            <b-tab :title="$t('Line Graph')">
              <line-graph
                :graph="graphValue"
                :category-type="catType"
              />
            </b-tab>
            <b-tab
              v-if="catType !== 'performance'"
              :title="$t('Overall Graph')"
            >
              <overall-graph
                :graph="graphValue"
                :category-type="catType"
              />
            </b-tab>
          </b-tabs>
          <!-- <b-pagination
        v-model="currentPage"
        class="d-flex justify-content-center"
        per-page="6"
        :total-rows="rows"
        @input="currFirstIdx = ((currentPage * 6) - 6), refreshGraph += 1, currLastIdx = ((currentPage * 6 > graphData.length) ? graphData.length - 1 : currentPage *6)"
      /> -->
        </div>
      </div>

      <b-card v-if="show">
        <!-- summary table -->
        <span class="saj-title">{{ $t("Summary Table") }}</span>
        <b-tabs content-class="mt-1">
          <b-tab
            :title="$t('Performance Grade')"
            active
          >
            <div class="container">
              <div class="d-flex justify-content-end">
                <b-form-input
                  v-model="searchSummary"
                  style="width: 250px;"
                  :placeholder="$t('Search') + '...'"
                  size="sm"
                  class="d-flex align-items-end"
                />
              </div>

              <b-table
                sticky-header="500px"
                responsive
                :items="summaryList"
                :fields="summaryFields"
                :filter="searchSummary"
                bordered
                show-empty
                style=""
                class="mt-1 mb-1 saj-subtitle"
              >
                <!-- <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template> -->
                <template #emptyfiltered="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #thead-top="">
                  <b-tr class="saj-title text-center">
                    <!--core-->
                    <b-th
                      class=""
                      variant="primary"
                    >
                      <!-- asdad -->
                      {{ $t('Filtered Items') }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Need Improvement") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Satisfactory") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Very Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Excellent") }}
                    </b-th>
                  </b-tr>
                </template>

              </b-table>
            </div>
          </b-tab>
          <b-tab
            :title="$t('HOD Evaluation')"
          >
            <div class="container">
              <div class="d-flex justify-content-end">
                <b-form-input
                  v-model="searchSummary1"
                  style="width: 250px;"
                  :placeholder="$t('Search') + '...'"
                  size="sm"
                  class="d-flex align-items-end"
                />
              </div>

              <b-table
                sticky-header="500px"
                responsive
                :items="summaryList1"
                :fields="summaryFields"
                :filter="searchSummary1"
                bordered
                show-empty
                style=""
                class="mt-1 mb-1 saj-subtitle"
              >
                <!-- <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template> -->
                <template #emptyfiltered="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #thead-top="">
                  <b-tr class="saj-title text-center">
                    <!--core-->
                    <b-th
                      class=""
                      variant="primary"
                    >
                      <!-- asdad -->
                      {{ $t('Filtered Items') }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Need Improvement") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Satisfactory") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Very Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Excellent") }}
                    </b-th>
                  </b-tr>
                </template>

              </b-table>
            </div>
          </b-tab>
          <b-tab
            :title="$t('Committee Evaluation')"
          >
            <div class="container">
              <div class="d-flex justify-content-end">
                <b-form-input
                  v-model="searchSummary2"
                  style="width: 250px;"
                  :placeholder="$t('Search') + '...'"
                  size="sm"
                  class="d-flex align-items-end"
                />
              </div>

              <b-table
                sticky-header="500px"
                responsive
                :items="summaryList2"
                :fields="summaryFields"
                :filter="searchSummary2"
                bordered
                show-empty
                style=""
                class="mt-1 mb-1 saj-subtitle"
              >
                <!-- <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template> -->
                <template #emptyfiltered="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #thead-top="">
                  <b-tr class="saj-title text-center">
                    <!--core-->
                    <b-th
                      class=""
                      variant="primary"
                    >
                      <!-- asdad -->
                      {{ $t('Filtered Items') }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Need Improvement") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Satisfactory") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Very Good") }}
                    </b-th>
                    <b-th
                      class="text-center saj-subtitle"
                      style=""
                      variant="primary"
                      :colspan="2"
                    >
                      {{ $t("Excellent") }}
                    </b-th>
                  </b-tr>
                </template>

              </b-table>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTr,
  BTh,
  BRow,
  // BCol,
  BFormSelect,
  BButton,
  BTable,
  BFormInput,
  // BCardGroup,
  // BPagination,
  BTab,
  BTabs,
  BOverlay,
  BSpinner,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import moment from "moment"
import OverallGraph from '@/views/pages/performance-statistics/overall_graph.vue'
import LineGraph from '@/views/pages/performance-statistics/line_graph.vue'
import BarGraph from '@/views/pages/performance-statistics/bar_graph.vue'

export default {
  components: {
    BCard,
    BRow,
    // BCol,
    BFormSelect,
    BButton,
    BTable,
    Datepicker,
    BFormInput,
    BTr,
    BTh,
    // BCardGroup,
    // BPagination,
    BTab,
    BTabs,
    OverallGraph,
    LineGraph,
    BarGraph,
    BOverlay,
    BSpinner,
  },

  data() {
    return {
      search: false,
      show: false,
      graphValue: null,
      catType: null,
      refreshGraph: 0,
      currentPage: 1,
      currFirstIdx: 0,
      currLastIdx: 6,
      rows: null,
      searchSummary: null,
      searchSummary1: null,
      searchSummary2: null,
      apex1Counter: 0,
      //   isSelectCompany: false,
      defaultDate: '2022',
      DatePickerFormat: 'yyyy',
      filterBy: null,
      tempSummaryField: [
        {
          label: '',
          key: 'filtered_items',
        },
        {
          // label: `${this.$i18n.t(`Need Improvement`)}`,
          label: '%',
          key: 'perluperingatan',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total4',
        },
        {
          // label: `${this.$i18n.t(`Satisfactory`)}`,
          label: '%',
          key: 'memuaskan',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total3',
        },
        {
          // label: `${this.$i18n.t(`Good`)}`,
          label: '%',
          key: 'baik',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total2',
        },
        {
          // label: `${this.$i18n.t(`Very Good`)}`,
          label: '%',
          key: 'sangatbaik',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total1',
        },
        {
          // label: `${this.$i18n.t(`Excellent`)}`,
          label: '%',
          key: 'cemerlang',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total',
        },
      ],
      summaryFields: [],
      summaryList1: [],
      summaryList2: [],
      summaryList: [],
      tempListFilter: [
        {
          text: `${this.$i18n.t(`Choose Category`)}`,
          value: null,
          disabled: true,
        },
        {
          text: `${this.$i18n.t(`Department`)}`,
          value: 'Department',
        },
        {
          text: `${this.$i18n.t(`Category`)}`,
          value: 'Category',
        },
        {
          text: `${this.$i18n.t(`Work Location`)}`,
          value: 'Worklocation',
        },
        {
          text: `${this.$i18n.t(`Gender`)}`,
          value: 'Gender',
        },
        {
          text: `${this.$i18n.t(`Grade Set`)}`,
          value: 'Gradeset',
        },
        {
          text: `${this.$i18n.t(`Grade`)}`,
          value: 'Grade',
        },
      ],
      selectedCycle: new Date(),
      cycleList: [],
      selectedDepartment: null,
      departmentList: [],
      selectedCategory: null,
      categoryList: [{
        value: 1,
        text: 'Executive',
      }, {
        value: 2,
        text: 'Non-Executive',
      }],
      listFilterBy: [],
      selectedCompany: 0,
      initCompanyList: [{
        value: 0,
        text: 'All',
      }],
      colors: ['#3C4EF2', '#00ACC1', '#FFA346', '#9646EF', "#999fff", "#88bcfc", "#00838F", "#C4C4C4", "#B2EBF2"],
      companyList: [],
      filterByLine: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            width: 90,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: '',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${val.toFixed(0)} %`
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: false,
          },
        },
      },
      // Sini Bar Graph
      filterByBar: {
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        }],
        chartOptions: {
          title: {
            text: 'Product Trends by Month',
            align: 'left',
          },
          chart: {
            type: 'bar',
            height: 350,
            width: 90,
            // zoom: {
            //   enabled: true,
            //   type: 'xy',
            //   resetIcon: {
            //     offsetX: -10,
            //     offsetY: 0,
            //     fillColor: '#fff',
            //     strokeColor: '#37474F',
            //   },
            //   selection: {
            //     background: '#90CAF9',
            //     border: '#0D47A1',
            //   },
            // },
          },
          plotOptions: {
            bar: {
              columnWidth: '100%',
              distributed: true,
            },
          },
          colors: [
            "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
            "#3F51B5", "#546E7A", "#D4526E", "#8D5B4C", "#F86624",
            "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
          ],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            scrollbar: {
              enabled: true,
            },
            type: 'category',
            // labels: {
            //   formatter(val) {
            //     console.log('val', this.$t(val))
            //     // return `${this.$i18n.t(val)}`
            //   },
            // },
          },
          fill: {
            opacity: 1,
          },
          yaxis: {
            labels: {
              // formatter(value) {
              //   let val = Math.abs(value)
              //   if (val >= 1000) {
              //     val = `${(val / 1000).toFixed(2)}`
              //   }
              //   return val
              // },
              formatter(val) {
                // console.log('val', val)
                return `${val.toFixed(0)} %`
              },
              scrollbar: {
                enabled: true,
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: false,
          },
        },
      },
      graphData: [],
      isOverall: false,

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      // this.getCompanyList()
      this.checkRoleFilter()
      this.tempSummaryField = [
        {
          label: '',
          key: 'filtered_items',
        },
        {
          // label: `${this.$i18n.t(`Need Improvement`)}`,
          label: '%',
          key: 'perluperingatan',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total4',
        },
        {
          // label: `${this.$i18n.t(`Satisfactory`)}`,
          label: '%',
          key: 'memuaskan',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total3',
        },
        {
          // label: `${this.$i18n.t(`Good`)}`,
          label: '%',
          key: 'baik',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total2',
        },
        {
          // label: `${this.$i18n.t(`Very Good`)}`,
          label: '%',
          key: 'sangatbaik',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total1',
        },
        {
          // label: `${this.$i18n.t(`Excellent`)}`,
          label: '%',
          key: 'cemerlang',
        },
        {
          label: `${this.$i18n.t(`Total Employee`)}`,
          key: 'total',
        },
      ]
      this.tempListFilter = [
        {
          text: `${this.$i18n.t(`Choose Category`)}`,
          value: null,
          disabled: true,
        },
        {
          text: `${this.$i18n.t(`Department`)}`,
          value: 'Department',
        },
        {
          text: `${this.$i18n.t(`Category`)}`,
          value: 'Category',
        },
        {
          text: `${this.$i18n.t(`Work Location`)}`,
          value: 'Worklocation',
        },
        {
          text: `${this.$i18n.t(`Gender`)}`,
          value: 'Gender',
        },
        {
          text: `${this.$i18n.t(`Grade Set`)}`,
          value: 'Gradeset',
        },
        {
          text: `${this.$i18n.t(`Grade`)}`,
          value: 'Grade',
        },
      ]
      this.listFilterBy = this.tempListFilter
      this.filter()
    },
  },
  mounted() {
    this.getCompanyList()
    // this.checkRoleFilter()
    this.listFilterBy = this.tempListFilter

    // setTimeout(() => {
    //   this.filter()
    // }, 1000)

    this.selectedCompany = this.userDetails.business_unit

    this.filter()
  },
  methods: {
    clearButton() {
      this.filterBy = null
      this.selectedCycle = new Date()
      this.graphData = []
      this.refreshGraph += 1
      this.isOverall = true
      this.show = false
      this.search = true
      this.filter()
    },
    customFormatter(date) {
      this.scoreYearSelected = moment(date).format('YYYY')
      return this.scoreYearSelected
    },
    getCompanyList() { // get all company
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/subsidiary/getAll`,
      }

      this.$axios(config)
        .then(response => {
          // console.log(response.data.data)
          const lists = response.data.data.subsidiaries
          const filteredList = lists.map(comp => ({
            value: comp.id,
            text: comp.subsidiary_name,
          }))
          this.companyList = this.initCompanyList.concat(filteredList)
        })
        .catch(() => {
          // console.log(error)
        })
    },
    checkRoleFilter() { // check filter options for each role
      // if (this.roles.isHOD) {
      //   console.log('masuk')
      //   this.listFilterBy = this.tempListFilter.filter(x => x !== 'Department')
      // } else {
      //   this.listFilterBy = this.tempListFilter
      // }
      this.listFilterBy = this.tempListFilter
    },
    async filter() {
      this.currentPage = 1
      const data = new FormData()
      data.append('year', moment(this.selectedCycle).year())
      data.append('company', this.userDetails.business_unit)
      data.append('filter', this.filterBy === null ? '' : this.filterBy.toLowerCase())
      data.append('status', 'active')

      try {
        const response = await this.$axios.post(`${this.$baseUrl}/dashboard/graph`, data)
        const datas = response.data.data
        let x = null

        if (this.selectedCompany !== 0){
          if (this.filterBy === null){
            x = 'performance'
          } else {
            x = this.filterBy.toLowerCase()
          }
        } else {
          x = ''
        }
        this.graphValue = this.filterBy === null ? datas.performance_grade : datas.graph
        this.catType = x
        // this.show = true
        this.search = false
        this.refreshGraph = 0
        if (this.filterBy === null){
          // console.log('masuk ke')
          this.isOverall = true
        }

        this.changeToGraph(x, this.graphValue)
      } catch (error) {
        console.error('ini err', error)
      }
    },
    changeToGraph(type, data) {
      let summary = []
      if (type === 'performance'){
        summary = data.map(x => x.summary !== 'company_name')
      } else {
        summary = data.map(x => x.summary)
      }
      if (summary.length !== 0) {
        this.summaryFields = this.tempSummaryField.concat(Object.keys(summary[0]))
      } else {
        this.summaryFields = []
      }

      let filterGenderTable = []

      // console.log('summ fields', this.summaryFields)

      switch (type) {
        case 'performance':
          // eslint-disable-next-line no-case-declarations, array-callback-return
          this.summaryList = data.map(item => ({

            filtered_items: item.summary.company_name,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))

          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.summary.company_name,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))

          this.summaryList2 = data.map(item => ({
            filtered_items: item.summary_final_evaluation.company_name,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))

          break
        case 'department':
          // eslint-disable-next-line no-case-declarations, array-callback-return
          this.summaryList = data.map(item => ({

            filtered_items: item.department_name,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,

          }))

          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.department_name,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))

          this.summaryList2 = data.map(item => ({
            filtered_items: item.department_name,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))

          break
        case 'category':

          this.summaryList = data.map(item => ({

            filtered_items: item.grade_set,
            // cemerlang: item.summary !== undefined ? item.summary.cemerlang : 0,
            // sangatbaik: item.summary !== undefined ? item.summary.sangatbaik : 0,
            // baik: item.summary !== undefined ? item.summary.baik : 0,
            // memuaskan: item.summary !== undefined ? item.summary.memuaskan : 0,
            // perluperingatan: item.summary !== undefined ? item.summary.perluperingatan : 0,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))

          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))

          this.summaryList2 = data.map(item => ({
            filtered_items: item.grade_set,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))
          break
        case 'worklocation':

          this.summaryList = data.map(item => ({
            filtered_items: item.branch_name,
            // cemerlang: item.summary !== undefined ? item.summary.cemerlang : 0,
            // sangatbaik: item.summary !== undefined ? item.summary.sangatbaik : 0,
            // baik: item.summary !== undefined ? item.summary.baik : 0,
            // memuaskan: item.summary !== undefined ? item.summary.memuaskan : 0,
            // perluperingatan: item.summary !== undefined ? item.summary.perluperingatan : 0,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))

          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.branch_name,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))

          this.summaryList2 = data.map(item => ({
            filtered_items: item.branch_name,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))
          break
        case 'gender':

          filterGenderTable = data.filter(item => (item.gender !== null && item.gender !== "null" && item.gender !== "1" && item.gender !== "2" && item.gender !== ""))

          // console.log("TESTING", filterGenderTable)

          this.summaryList = filterGenderTable.map(item => ({
            filtered_items: item.gender,
            // filtered_items: item.gender,
            // cemerlang: item.summary !== undefined ? item.summary.cemerlang : 0,
            // sangatbaik: item.summary !== undefined ? item.summary.sangatbaik : 0,
            // baik: item.summary !== undefined ? item.summary.baik : 0,
            // memuaskan: item.summary !== undefined ? item.summary.memuaskan : 0,
            // perluperingatan: item.summary !== undefined ? item.summary.perluperingatan : 0,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))
          this.summaryList1 = filterGenderTable.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.gender,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))
          this.summaryList2 = filterGenderTable.map(item => ({
            filtered_items: item.gender,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))

          break
        case 'gradeset':

          this.summaryList = data.map(item => ({
            filtered_items: item.grade_set,
            // cemerlang: item.summary !== undefined ? item.summary.cemerlang : 0,
            // sangatbaik: item.summary !== undefined ? item.summary.sangatbaik : 0,
            // baik: item.summary !== undefined ? item.summary.baik : 0,
            // memuaskan: item.summary !== undefined ? item.summary.memuaskan : 0,
            // perluperingatan: item.summary !== undefined ? item.summary.perluperingatan : 0,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))
          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))
          this.summaryList2 = data.map(item => ({
            filtered_items: item.grade_set,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))

          break
        case 'grade':

          this.summaryList = data.map(item => ({
            filtered_items: item.grade,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))
          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))
          this.summaryList2 = data.map(item => ({
            filtered_items: item.grade,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))

          break
        default:

          this.summaryList = data.map(item => ({
            filtered_items: item.comp_name,
            // cemerlang: item.summary !== undefined ? item.summary.cemerlang : 0,
            // sangatbaik: item.summary !== undefined ? item.summary.sangatbaik : 0,
            // baik: item.summary !== undefined ? item.summary.baik : 0,
            // memuaskan: item.summary !== undefined ? item.summary.memuaskan : 0,
            // perluperingatan: item.summary !== undefined ? item.summary.perluperingatan : 0,
            cemerlang: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
            baik: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            total: item.summary !== undefined ? item.summary.cemerlang : 0,
            total1: item.summary !== undefined ? item.summary.sangatbaik : 0,
            total2: item.summary !== undefined ? item.summary.baik : 0,
            total3: item.summary !== undefined ? item.summary.memuaskan : 0,
            total4: item.summary !== undefined ? item.summary.perluperingatan : 0,
          }))

          this.summaryList1 = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.comp_name,
            cemerlang: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary_hod_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.cemerlang : 0,
            total1: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.sangatbaik : 0,
            total2: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.baik : 0,
            total3: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.memuaskan : 0,
            total4: item.summary_hod_evaluation !== undefined ? item.summary_hod_evaluation.perluperingatan : 0,
            // }
            // }
          }))

          this.summaryList2 = data.map(item => ({
            filtered_items: item.comp_name,
            cemerlang: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            sangatbaik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            baik: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            memuaskan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            perluperingatan: item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
            total: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.cemerlang : 0,
            total1: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.sangatbaik : 0,
            total2: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.baik : 0,
            total3: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.memuaskan : 0,
            total4: item.summary_final_evaluation !== undefined ? item.summary_final_evaluation.perluperingatan : 0,
          }))
      }
      // console.log('summary list after', this.summaryList)

      // eslint-disable-next-line no-plusplus
      this.apex1Counter++
      this.show = true
    },

    // setSummaryData(data, type){
    //   let finalData = []
    //   console.log(data[0].department_name)
    //   finalData = data.map(item => ({
    //     filtered_items: item.department_name, cemerlang: item.summary.cemerlang, sangatbaik: item.summary.sangatbaik, baik: item.summary.baik, memuaskan: item.summary.memuaskan, perluperingatan: item.summary.perluperingatan,
    //   }))

    //   return finalData
    // },
  },
}
</script>

<style scoped>

.show-only-year .vdp-datepicker {
    text-align: center !important;
}

.show-only-year .vdp-datepicker__calendar {
    /* bottom: 2px; */
    background: white;
    font-size: 14px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.show-only-year .vdp-datepicker__calendar .cell.selected {
    background: #ff0000;
    border-radius: 6px;
}

.show-only-year .vdp-datepicker__calendar .cell:hover {
    border: 1px solid #ff0000 !important;
    border-radius: 6px;
}
.year-custom {
  background: #fff;
  border-radius: 6px;
  padding-left: 12px;
  border: none;
  height: 53px;
  font-size: 19px;
  color: grey;
  border: 1px solid #d8d6de;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
