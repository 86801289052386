<template>
  <div>
    <div v-if="roles.isAdmin">
      <statistics-admin />
    </div>
    <div v-else-if="roles.isHR">
      <statistics-hr />
    </div>
    <div v-else>
      <statistics-hod />
    </div>
  </div>
</template>

<script>
import {
} from "bootstrap-vue"
import { mapGetters } from 'vuex'

import statisticsHr from '@/views/pages/performance-statistics/performance-statistics_hr.vue'
import statisticsHod from '@/views/pages/performance-statistics/performance-statistics_hod.vue'
import statisticsAdmin from '@/views/pages/performance-statistics/performance-statistics_admin.vue'

export default {
  components: {
    statisticsHod,
    statisticsAdmin,
    statisticsHr,
  },

  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
  },
  mounted() {

  },
  methods: {
  },
}
</script>

<style scoped>
</style>
<style>
.show-only-year .vdp-datepicker__calendar {
    left:  0px !important;
    text-align: center !important;
}
.show-only-year input{
    text-align: center;
    margin: 0;
    /* font-family: inherit;
    font-size: inherit;
    line-height: inherit; */
    height: 2.4rem !important;
    font-size: 0.857rem;
    border: 1px solid #d8d6de;
    color: #6e6b7b;
    border-radius: 0.357rem;
}
</style>
