<template>
  <div>
    <div
      v-if="graphData.length !== 0"
      class=""
    >
      <!-- {{graph}} -->
      <!-- {{categoryType}} -->

      <div :key="refreshGraph">
        <!-- {{ filterByBar.series }} -->
        <b-card
          height="800px"
          class=""
          cols="6"
        >
          <!-- {{ filterByBar.series }} -->
          <vue-apex-charts
            :key="apex1Counter"
            type="bar"
            height="auto"
            :options="filterByBar.chartOptions"
            :series="filterByBar.series"
            class=""
          />
        </b-card>

        <b-card
          height="800px"
          class=""
          cols="6"
        >
          <!-- {{ filterByLine.series }} -->
          <!-- {{ filterByLine.series.data }} -->
          <vue-apex-charts
            :key="apex1Counter"
            type="line"
            height="auto"
            :options="filterByLine.chartOptions"
            :series="filterByLine.series"
            class=""
          />
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  // BCol,
  // BCardGroup,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import VueApexCharts from "vue-apexcharts"
// import Datepicker from 'vuejs-datepicker'
import moment from "moment"
// import { stringify } from 'qs'

export default {
  components: {
    BCard,
    // BCol,
    VueApexCharts,
    // BCardGroup,
  },

  props: {
    graph: {
      type: Array,
      default: () => {},
    },
    categoryType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      apex1Counter: 0,
      refreshGraph: 0,
      currentPage: 1,
      currFirstIdx: 0,
      currLastIdx: 6,
      rows: null,
      //   isSelectCompany: false,
      colors: ['#3C4EF2', '#00ACC1', '#FFA346', '#9646EF', "#999fff", "#88bcfc", "#00838F", "#C4C4C4", "#B2EBF2"],
      filterByLine: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            width: 90,
            type: 'line',
            // zoom: {
            //   enabled: false,
            // },
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                // eslint-disable-next-line no-bitwise
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: [],
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: '',
            align: 'center',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
            title: {
              text: "Performance Grade",
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${val.toFixed(0)} %`
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
            title: {
              text:this.$t("Percentage"),
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                // console.log(s'y', val)
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: false,
          },
        },
      },
      // Sini Bar Graph
      filterByBar: {
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        }],
        chartOptions: {
          title: {
            text: 'Product Trends by Month',
            align: 'center',
          },
          chart: {
            type: 'bar',
            height: 350,
            width: 90,
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                // eslint-disable-next-line no-bitwise
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: [],
              },
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '100%',
              distributed: true,
            },
          },
          colors: [
            "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
            "#3F51B5", "#546E7A", "#D4526E", "#8D5B4C", "#F86624",
            "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
          ],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            scrollbar: {
              enabled: true,
            },
            type: 'category',
            // labels: {
            //   formatter(val) {
            //     console.log('val', this.$t(val))
            //     // return `${this.$i18n.t(val)}`
            //   },
            // },
            title: {
              text: "Performance Grade",
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          fill: {
            opacity: 1,
          },
          yaxis: {
            labels: {
              // formatter(value) {
              //   let val = Math.abs(value)
              //   if (val >= 1000) {
              //     val = `${(val / 1000).toFixed(2)}`
              //   }
              //   return val
              // },
              formatter(val) {
                // console.log('val', val)
                return `${val.toFixed(0)} %`
              },
              scrollbar: {
                enabled: true,
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
            title: {
              text: this.$t("Percentage"),
              // offsetX: 0,
              // offsetY: 0,
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: false,
          },
        },
      },
      graphData: [],

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.forceRerenderGraph()
    },
  },
  mounted() {
    //  console.log('nad', this.graph)
    // setTimeout(() => {

    this.changeToGraph(this.categoryType, this.graph)
    // }, 1000)
    // console.log('type', this.categoryType)
  },
  methods: {
    forceRerenderGraph(){
      this.changeToGraph(this.categoryType, this.graph)
      this.filterByLine.chartOptions.yaxis.title.text = this.$t("Percentage")
      this.filterByBar.chartOptions.yaxis.title.text = this.$t("Percentage")
    },
    range(start, end) {
      // const arr = [1, 2, 3, 4, 5].map((_, idx) => start + idx)
      // console.log('arr', arr)
      // console.log(end, start)
      // console.log('array 5', Array(end - start))
      return Array(end - start !== 0 ? (end - start) : 1).fill().map((_, idx) => start + idx)
    },
    customFormatter(date) {
      this.scoreYearSelected = moment(date).format('YYYY')
      return this.scoreYearSelected
    },
    changeToGraph(type, data) {
      let filterGenderXaxis = []

      let filterWLXaxis = []

      // let filterDeptXaxis = []

      let filterGradeSetXaxis = []

      let filterGradeXaxis = []

      let filterCompanyXaxis = []

      // console.log('summ fields', this.summaryFields)

      switch (type) {
        case 'department':
          // eslint-disable-next-line no-case-declarations, array-callback-return
        //   const temp = data.map(x => x.score)
        //   console.log('temp', temp)
          // line chart
          this.filterByLine.series[0].data = data.map(x => ({
            x: x.department_name,
            y: x.score.toFixed(0),
          }))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Department')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Department')

          this.filterByBar.chartOptions.title.text = this.$t('All Departments')
          this.filterByLine.chartOptions.title.text = this.$t('All Departments')
          // filterDeptXaxis = data.filter(x => (x.score !== 0))
          // this.filterByBar.chartOptions.xaxis.categories = filterDeptXaxis.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          // this.filterByBar.series[0].name = data.map(x => x.department_name)
          // eslint-disable-next-line no-case-declarations
          const temp = data.map(x => ({
            name: x.department_name,
            data: [x.score.toFixed(2)],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp
          // console.log('sa', )

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.department_name,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          // this.filterByLine.series.data = data.map(item => ({
          //   name: item.department_name,
          //   data: [
          //     item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
          //     item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
          //     item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
          //     item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
          //     item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
          //   ],
          // }))

          this.filterByBar.chartOptions.xaxis.categories = temp.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp.map(x => (x.name))

          this.rows = this.graphData.length

          // console.log('name', this.graphData[0].summary)
          // console.log(data)
          // console.log('cat', this.filterByBar.chartOptions.xaxis.categories)
          // console.log('try data', this.graphData)

          break
        case 'category':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Category' : x.grade_set))
          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Category')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Category')

          this.filterByBar.chartOptions.title.text = this.$t('All Categories')
          this.filterByLine.chartOptions.title.text = this.$t('All Categories')
          this.filterByBar.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Category' : x.grade_set))

          // this.filterByBar.series[0].data = data.map(x => x.score)
          // eslint-disable-next-line no-case-declarations
          const temp1 = data.map(x => ({
            name: x.grade_set,
            data: [x.score.toFixed(2)],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp1

          // console.log('item', data[0].grade_set)

          // data.forEach(item => {
          //   console.log('data', item.grade_set)
          //   console.log('data', item.summary)
          // })

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(item => ({
            name: item.grade_set,
            data: [
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = temp1.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp1.map(x => (x.name))
          // console.log('length', data.length)

          break
        case 'worklocation':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.branch_name === null ? 'No Branch' : x.branch_name))

          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Work Location')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Work Location')

          this.filterByBar.chartOptions.title.text = this.$t('All Work Locations')
          this.filterByLine.chartOptions.title.text = this.$t('All Work Locations')

          filterWLXaxis = data.filter(x => (x.score !== 0))
          this.filterByBar.chartOptions.xaxis.categories = filterWLXaxis.map(x => (x.branch_name === null ? 'No Branch' : x.branch_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          // eslint-disable-next-line no-case-declarations
          const temp2 = data.map(x => ({
            name: x.branch_name,
            data: [x.score.toFixed(2)],
          }))
          // console.log('wl', temp2)
          this.filterByBar.series = temp2

          // console.log('wl', data)
          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.branch_name,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(item => ({
            name: item.branch_name,
            data: [
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = temp2.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp2.map(x => (x.name))

          this.rows = this.graphData.length
          break
        case 'gender':
        // line chart
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.gender === null ? 'No Gender' : x.gender))

          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Gender')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Gender')

          this.filterByBar.chartOptions.title.text = this.$t('All Genders')
          this.filterByLine.chartOptions.title.text = this.$t('All Genders')

          filterGenderXaxis = data.filter(x => (x.gender !== null && x.gender !== "null" && x.gender !== "1" && x.gender !== "2" && x.gender !== ""))
          this.filterByBar.chartOptions.xaxis.categories = filterGenderXaxis.map(x => (x.gender === null ? 'No Gender' : x.gender))

          // eslint-disable-next-line no-case-declarations
          const temp3 = filterGenderXaxis.map(x => ({
            name: x.gender,
            data: [x.score.toFixed(2)],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp3

          // console.log("TESTING", filterGenderTable)

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.gender,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * item.score).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * item.score).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * item.score).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * item.score).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * item.score).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(item => ({
            name: item.gender,
            data: [
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = temp3.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp3.map(x => (x.name))

          this.rows = this.graphData.length
          // console.log('try data', this.graphData)

          break
        case 'gradeset':
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Gradeset' : x.grade_set))

          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Grade Set')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Grade Set')

          this.filterByBar.chartOptions.title.text = this.$t('All Grade Sets')
          this.filterByLine.chartOptions.title.text = this.$t('All Grade Sets')

          filterGradeSetXaxis = data.filter(x => (x.score !== 0))
          this.filterByBar.chartOptions.xaxis.categories = filterGradeSetXaxis.map(x => (x.grade_set === null ? 'No Gradeset' : x.grade_set))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          // eslint-disable-next-line no-case-declarations
          const temp4 = data.map(x => ({
            name: x.grade_set,
            data: [x.score.toFixed(2)],
          }))
          // console.log('gradeset', temp4)
          this.filterByBar.series = temp4

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(item => ({
            name: item.grade_set,
            data: [
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = temp4.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp4.map(x => (x.name))

          this.rows = this.graphData.length

          break
        case 'grade':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade === null ? 'No Grade' : x.grade))

          this.filterByLine.chartOptions.xaxis.title.text = this.$t('Grade')
          this.filterByBar.chartOptions.xaxis.title.text = this.$t('Grade')

          this.filterByBar.chartOptions.title.text = this.$t('All Grades')
          this.filterByLine.chartOptions.title.text = this.$t('All Grades')

          filterGradeXaxis = data.filter(x => x.score !== 0)
          this.filterByBar.chartOptions.xaxis.categories = filterGradeXaxis.map(x => (x.grade === null ? 'No Grade' : x.grade))
          // this.filterByBar.series[0].data = data.map(x => x.score)

          // eslint-disable-next-line no-case-declarations
          const temp5 = data.map(x => ({
            name: x.grade,
            data: [x.score.toFixed(2)],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp5

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(item => ({
            name: item.grade,
            data: [
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
              item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = temp5.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = temp5.map(x => (x.name))

          this.rows = this.graphData.length

          break
        default:
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(0))
          this.filterByLine.series[0].name = 'Score'
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.comp_name === null ? 'No Company' : x.comp_name))

          if (!this.roles.isAdmin) {
            this.filterByLine.chartOptions.title.text = this.$t('Company')
            // bar chart
            this.filterByBar.chartOptions.title.text = this.$t('Company')
          } else {
            this.filterByLine.chartOptions.title.text = this.$t('All companies')
            // bar chart
            this.filterByBar.chartOptions.title.text = this.$t('All companies')
          }
          filterCompanyXaxis = data.filter(x => x.score !== 0)
          this.filterByBar.chartOptions.xaxis.categories = filterCompanyXaxis.map(x => (x.comp_name === null ? 'No Company' : x.comp_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)

          // console.log('company label', filterCompanyLabel)
          // eslint-disable-next-line no-case-declarations
          const temp6 = data.map(x => ({
            name: x.comp_name,
            data: [x.score.toFixed(2)],
          }))

          // console.log('data', data)
          // console.log('dep', temp6)
          this.filterByBar.series = temp6

          // console.log('summary list before', data[1].summary.cemerlang, data[1].summary.total, data[1].score)

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.comp_name,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(x => ({
            name: x.comp_name,
            data: [
              x.score.toFixed(2),
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = data.map(x => (x.comp_name))
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.comp_name))

          this.rows = this.graphData.length
      }
      // console.log('summary list after', this.summaryList)

      // eslint-disable-next-line no-plusplus
      this.apex1Counter++
    },

    // setSummaryData(data, type){
    //   let finalData = []
    //   console.log(data[0].department_name)
    //   finalData = data.map(item => ({
    //     filtered_items: item.department_name, cemerlang: item.summary.cemerlang, sangatbaik: item.summary.sangatbaik, baik: item.summary.baik, memuaskan: item.summary.memuaskan, perluperingatan: item.summary.perluperingatan,
    //   }))

    //   return finalData
    // },
  },
}
</script>

<style scoped>

.show-only-year .vdp-datepicker {
    text-align: center !important;
}

.show-only-year .vdp-datepicker__calendar {
    /* bottom: 2px; */
    background: white;
    font-size: 14px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.show-only-year .vdp-datepicker__calendar .cell.selected {
    background: #ff0000;
    border-radius: 6px;
}

.show-only-year .vdp-datepicker__calendar .cell:hover {
    border: 1px solid #ff0000 !important;
    border-radius: 6px;
}
.year-custom {
  background: #fff;
  border-radius: 6px;
  padding-left: 12px;
  border: none;
  height: 53px;
  font-size: 19px;
  color: grey;
  border: 1px solid #d8d6de;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
