<template>
  <div>
    <div v-if="categoryType === 'performance'">
      <b-card
        v-for="(item, idx) in graphData"
        :key="idx"
        class=""
        cols="6"
      >

        <p style="font-weight: 600; text-align: center; color: black">
          <!-- {{ filterByLine.series.data[idx].name }} -->
          {{ $t(chartName[idx]) }}
        </p>

        <!-- {{ idx }} -->
        <!-- {{ cat.summary }} -->
        <vue-apex-charts
          :key="apex1Counter"
          type="line"
          height="450px"
          :options="filterByLine.chartOptions"
          :series="filterByLine.series.data[idx]"
          class=""
        />
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="graphData.length !== 0"
        class=""
      >
        <!-- {{graph}} -->
        <!-- {{categoryType}} -->
        <!-- {{ graphData }} -->

        <div

          :key="refreshGraph"
        >

          <div
            v-if="graphData.length > 6"
            class="row"
          >
            <b-card
              v-for="idx in range(currFirstIdx, currLastIdx)"
              :key="idx"
              height="800px"
              class="m-1"
              cols="6"
            >
              <p style="font-weight: 600; text-align: center; color: black">
                <!-- {{ filterByLine.chartOptions.title }} -->
                {{ $t(chartName[idx]) }}
              </p>
              <!-- {{ idx }} -->
              <!-- {{ cat.summary }} -->
              <vue-apex-charts
                :key="apex1Counter"
                type="line"
                :options="filterByLine.chartOptions"
                :series="filterByLine.series.data[idx]"
                class=""
                width="540px"
              />
            </b-card>
          </div>

          <div
            v-else
            class="row"
          >
            <b-card
              v-for="(item, idx) in graphData"
              :key="idx"
              height="800px"
              class="m-1"
              cols="6"
            >

              <p style="font-weight: 600; text-align: center; color: black">
                <!-- {{ filterByLine.series.data[idx].name }} -->
                {{ $t(chartName[idx]) }}
              </p>

              <!-- {{ idx }} -->
              <!-- {{ cat.summary }} -->
              <vue-apex-charts
                :key="apex1Counter"
                type="line"
                :options="filterByLine.chartOptions"
                :series="filterByLine.series.data[idx]"
                class=""
                width="540px"
              />
            </b-card>
          </div>

        </div>

        <b-pagination
          v-model="currentPage"
          class="d-flex justify-content-center"
          per-page="6"
          :total-rows="rows"
          @input="currFirstIdx = ((currentPage * 6) - 6), refreshGraph += 1, currLastIdx = ((currentPage * 6 > graphData.length) ? graphData.length : currentPage *6)"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {
  BCard,
  // BCol,
  // BCardGroup,
  BPagination,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import VueApexCharts from "vue-apexcharts"
// import Datepicker from 'vuejs-datepicker'
import moment from "moment"
// import { stringify } from 'qs'

export default {
  components: {
    BCard,
    // BCol,
    VueApexCharts,
    // BCardGroup,
    BPagination,
  },

  props: {
    graph: {
      type: Array,
      default: () => {},
    },
    categoryType: {
      type: String,
      default: null,
    },
    isDiv: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartName: [],
      refreshGraph: 0,
      currentPage: 1,
      currFirstIdx: 0,
      currLastIdx: 6,
      rows: null,
      //   isSelectCompany: false,
      colors: ['#3C4EF2', '#00ACC1', '#FFA346', '#9646EF', "#999fff", "#88bcfc", "#00838F", "#C4C4C4", "#B2EBF2"],
      filterByLine: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            width: 90,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: '',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("Performance Grade"),
              // offsetX: 0,
              // offsetY: 0,
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${val.toFixed(0)} %`
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
            title: {
              text: this.$t("Percentage"),
              // offsetX: 0,
              // offsetY: 0,
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: true,
            position: 'top',
          },
        },
      },
      // Sini Bar Graph
      filterByBar: {
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        }],
        chartOptions: {
          title: {
            text: '',
            align: 'left',
          },
          chart: {
            type: 'bar',
            height: 350,
            width: 90,
            // zoom: {
            //   enabled: true,
            //   type: 'xy',
            //   resetIcon: {
            //     offsetX: -10,
            //     offsetY: 0,
            //     fillColor: '#fff',
            //     strokeColor: '#37474F',
            //   },
            //   selection: {
            //     background: '#90CAF9',
            //     border: '#0D47A1',
            //   },
            // },
          },
          plotOptions: {
            bar: {
              columnWidth: '100%',
              distributed: true,
            },
          },
          colors: [
            "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
            "#3F51B5", "#546E7A", "#D4526E", "#8D5B4C", "#F86624",
            "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
          ],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            scrollbar: {
              enabled: true,
            },
            type: 'category',
            // labels: {
            //   formatter(val) {
            //     console.log('val', this.$t(val))
            //     // return `${this.$i18n.t(val)}`
            //   },
            // },
          },
          fill: {
            opacity: 1,
          },
          yaxis: {
            labels: {
              // formatter(value) {
              //   let val = Math.abs(value)
              //   if (val >= 1000) {
              //     val = `${(val / 1000).toFixed(2)}`
              //   }
              //   return val
              // },
              formatter(val) {
                // console.log('val', val)
                return `${val.toFixed(0)} %`
              },
              scrollbar: {
                enabled: true,
              },
            },
            min: 0,
            max: 100,
            tickAmount: 10,
          },
          tooltip: {
            // theme: 'dark',
            shared: false,
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter(val) {
                return val.toFixed(0)
              },
            },
            // fillSeriesColor: true,
          },
          legend: {
            show: false,
          },
        },
      },
      graphData: [],

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.forceRerenderGraph()
    },
  },
  mounted() {
    this.changeToGraph(this.categoryType, this.graph)
    // console.log('graph', this.graph)
  },
  methods: {
    forceRerenderGraph(){
      this.changeToGraph(this.categoryType, this.graph)
      this.filterByLine.chartOptions.xaxis.title.text = this.$t("Performance Grade")
      this.filterByLine.chartOptions.yaxis.title.text = this.$t("Percentage")
    },
    range(start, end) {
      // const arr = [1, 2, 3, 4, 5].map((_, idx) => start + idx)
      // console.log('arr', arr)
      // console.log(end, start)
      // console.log('array 5', Array(end - start))
      return Array(end - start !== 0 ? (end - start) : 1).fill().map((_, idx) => start + idx)
    },
    customFormatter(date) {
      this.scoreYearSelected = moment(date).format('YYYY')
      return this.scoreYearSelected
    },
    changeToGraph(type, data) {
      let filterGenderScore = []
      let filterGenderXaxis = []

      let filterWLXaxis = []
      let filterWLLabel = []

      // let filterDeptXaxis = []
      let filterDeptLabel = []

      let filterGradeSetXaxis = []
      let filterGradeSetLabel = []

      let filterGradeXaxis = []
      let filterGradeLabel = []

      let filterCompanyXaxis = []

      // console.log('summ fields', this.summaryFields)
      switch (type) {
        case 'performance':
          // eslint-disable-next-line no-case-declarations, array-callback-return
        //   const temp = data.map(x => x.score)
        //   console.log('temp', temp)
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          // this.filterByLine.chartOptions.title = 'tajuk'
          // this.filterByLine.chartOptions.title.text = this.$t('Department')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Total')
          // filterDeptXaxis = data.filter(x => (x.score !== 0))
          // this.filterByBar.chartOptions.xaxis.categories = filterDeptXaxis.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          // this.filterByBar.series[0].name = data.map(x => x.department_name)
          filterDeptLabel = data.filter(x => (x.score !== 0))
          // eslint-disable-next-line no-case-declarations
          const temp0 = filterDeptLabel.map(x => ({
            name: x.department_name,
            data: [x.score],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp0
          // console.log('sa', )

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.summary.company_name,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.summary.company_name))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length

          // console.log('name', this.graphData[0].summary)
          // console.log(data)
          // console.log('cat', this.filterByBar.chartOptions.xaxis.categories)
          // console.log('try data', this.graphData)

          break
        case 'department':
          // eslint-disable-next-line no-case-declarations, array-callback-return
        //   const temp = data.map(x => x.score)
        //   console.log('temp', temp)
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          // this.filterByLine.chartOptions.title = 'tajuk'
          // this.filterByLine.chartOptions.title.text = this.$t('Department')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Total')
          // filterDeptXaxis = data.filter(x => (x.score !== 0))
          // this.filterByBar.chartOptions.xaxis.categories = filterDeptXaxis.map(x => (x.department_name === null ? 'No Department' : x.department_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          // this.filterByBar.series[0].name = data.map(x => x.department_name)
          filterDeptLabel = data.filter(x => (x.score !== 0))
          // eslint-disable-next-line no-case-declarations
          const temp = filterDeptLabel.map(x => ({
            name: x.department_name,
            data: [x.score],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp
          // console.log('sa', )

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.department_name,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.department_name))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length

          // console.log('name', this.graphData[0].summary)
          // console.log(data)
          // console.log('cat', this.filterByBar.chartOptions.xaxis.categories)
          // console.log('try data', this.graphData)

          break
        case 'category':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Category' : x.grade_set))
          // this.filterByLine.chartOptions.title.text = this.$t('Category')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Total')
          this.filterByBar.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Category' : x.grade_set))

          // this.filterByBar.series[0].data = data.map(x => x.score)
          // eslint-disable-next-line no-case-declarations
          const temp1 = data.map(x => ({
            name: x.grade_set,
            data: [x.score],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp1

          // console.log('item', data[0].grade_set)

          // data.forEach(item => {
          //   console.log('data', item.grade_set)
          //   console.log('data', item.summary)
          // })

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.grade_set))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          // console.log('length', data.length)

          break
        case 'worklocation':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.branch_name === null ? 'No Branch' : x.branch_name))
          // this.filterByLine.chartOptions.title.text = this.$t('Work Location')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Work Location')
          filterWLXaxis = data.filter(x => (x.score !== 0))
          this.filterByBar.chartOptions.xaxis.categories = filterWLXaxis.map(x => (x.branch_name === null ? 'No Branch' : x.branch_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          filterWLLabel = data.filter(x => (x.score !== 0))
          // eslint-disable-next-line no-case-declarations
          const temp2 = filterWLLabel.map(x => ({
            name: x.branch_name,
            data: [x.score],
          }))
          // console.log('wl', temp2)
          this.filterByBar.series = temp2

          // console.log('wl', data)
          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.branch_name,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.branch_name))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length
          break
        case 'gender':
        // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.gender === null ? 'No Gender' : x.gender))
          // this.filterByLine.chartOptions.title.text = this.$t('Gender')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Gender')

          filterGenderXaxis = data.filter(x => (x.gender !== null && x.gender !== "null" && x.gender !== "1" && x.gender !== "2" && x.gender !== ""))
          this.filterByBar.chartOptions.xaxis.categories = filterGenderXaxis.map(x => (x.gender === null ? 'No Gender' : x.gender))

          filterGenderScore = data.filter(x => (x.gender !== null && x.gender !== "null" && x.gender !== "1" && x.gender !== "2" && x.gender !== ""))
          // eslint-disable-next-line no-case-declarations
          const temp3 = data.map(x => ({
            name: x.gender,
            data: [x.score],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp3

          // console.log("TESTING", filterGenderTable)

          this.graphData = filterGenderScore.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.gender,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.gender))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length
          // console.log('try data', this.graphData)

          break
        case 'gradeset':
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade_set === null ? 'No Gradeset' : x.grade_set))
          // this.filterByLine.chartOptions.title.text = this.$t('Grade Set')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Grade Set')
          filterGradeSetXaxis = data.filter(x => (x.score !== 0))
          this.filterByBar.chartOptions.xaxis.categories = filterGradeSetXaxis.map(x => (x.grade_set === null ? 'No Gradeset' : x.grade_set))
          // this.filterByBar.series[0].data = data.map(x => x.score)
          filterGradeSetLabel = data.filter(x => x.score !== 0)
          // eslint-disable-next-line no-case-declarations
          const temp4 = filterGradeSetLabel.map(x => ({
            name: x.grade_set,
            data: [x.score],
          }))
          // console.log('gradeset', temp4)
          this.filterByBar.series = temp4

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade_set,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.grade_set))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length

          break
        case 'grade':
          // line chart
          this.filterByLine.series[0].data = data.map(x => x.score)
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.grade === null ? 'No Grade' : x.grade))
          // this.filterByLine.chartOptions.title.text = this.$t('Grade')
          // bar chart
          // this.filterByBar.chartOptions.title.text = this.$t('Grade')
          filterGradeXaxis = data.filter(x => x.score !== 0)
          this.filterByBar.chartOptions.xaxis.categories = filterGradeXaxis.map(x => (x.grade === null ? 'No Grade' : x.grade))
          // this.filterByBar.series[0].data = data.map(x => x.score)

          filterGradeLabel = data.filter(x => x.score !== 0)
          // eslint-disable-next-line no-case-declarations
          const temp5 = filterGradeLabel.map(x => ({
            name: x.grade,
            data: [x.score],
          }))
          // console.log('dep',temp )
          this.filterByBar.series = temp5

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.grade,
            summary: [
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.chartName = data.map(item => (item.grade))

          if (this.roles.isAdmin || this.isDiv || this.roles.isHR){
            this.filterByLine.series.data = data.map(item => [
              {
                name: this.$t('Performance Grade'),
                data: [
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('HOD Evaluation'),
                data: [
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                  item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
                ],
              },
              {
                name: this.$t('Committee Evaluation'),
                data: [
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.perluperingatan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.memuaskan / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.baik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.sangatbaik / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                  item.summary_final_evaluation !== undefined && item.summary_final_evaluation.total !== 0 ? ((item.summary_final_evaluation.cemerlang / item.summary_final_evaluation.total) * 100).toFixed(0) : 0,
                ],
              },
            ])
          } else {
            this.filterByLine.series.data = data.map(item => [{
              name: this.$t('Performance Grade'),
              data: [
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            },
            {
              name: this.$t('HOD Evaluation'),
              data: [
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.perluperingatan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.memuaskan / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.baik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.sangatbaik / item.summary.total) * 100).toFixed(0) : 0,
                item.summary_hod_evaluation !== undefined && item.summary_hod_evaluation.total !== 0 ? ((item.summary_hod_evaluation.cemerlang / item.summary.total) * 100).toFixed(0) : 0,
              ],
            }])
          }

          this.filterByBar.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))
          this.filterByLine.chartOptions.xaxis.categories = this.graphData[0].summary.map(x => (x.name))

          this.rows = this.graphData.length

          break
        default:
          this.filterByLine.series[0].data = data.map(x => x.score.toFixed(2))
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.comp_name === null ? 'No Company' : x.comp_name))

          if (!this.roles.isAdmin) {
            this.filterByLine.chartOptions.title.text = this.$t('Company')
            // bar chart
            this.filterByBar.chartOptions.title.text = this.$t('Company')
          } else {
           
            this.filterByLine.chartOptions.title.text = this.$t('All company')
            // bar chart
            this.filterByBar.chartOptions.title.text = this.$t('All company')
          }
          filterCompanyXaxis = data.filter(x => x.score !== 0)
          this.filterByBar.chartOptions.xaxis.categories = filterCompanyXaxis.map(x => (x.comp_name === null ? 'No Company' : x.comp_name))
          // this.filterByBar.series[0].data = data.map(x => x.score)

          // console.log('company label', filterCompanyLabel)
          // eslint-disable-next-line no-case-declarations
          const temp6 = data.map(x => ({
            name: x.comp_name,
            data: [x.score.toFixed(2)],
          }))

          // console.log('data', data)
          // console.log('dep', temp6)
          this.filterByBar.series = temp6

          // console.log('summary list before', data[1].summary.cemerlang, data[1].summary.total, data[1].score)

          this.graphData = data.map(item => ({
            // if (item.summary !== undefined) {
            // const tempObj = {
            filtered_items: item.comp_name,
            summary: [
              {
                name: `${this.$i18n.t(`cemerlang`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.cemerlang / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`sangatbaik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.sangatbaik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`baik`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.baik / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`memuaskan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.memuaskan / item.summary.total) * 100).toFixed(0) : 0],
              },
              {
                name: `${this.$i18n.t(`perluperingatan`)}`,
                data: [item.summary !== undefined && item.summary.total !== 0 ? ((item.summary.perluperingatan / item.summary.total) * 100).toFixed(0) : 0],
              },
            ],
          }))

          this.filterByLine.series.data = data.map(x => ({
            name: x.comp_name,
            data: [
              x.score.toFixed(2),
            ],
          }))

          this.filterByBar.chartOptions.xaxis.categories = data.map(x => (x.comp_name))
          this.filterByLine.chartOptions.xaxis.categories = data.map(x => (x.comp_name))

          this.rows = this.graphData.length
      }
      // console.log('summary list after', this.summaryList)

      // eslint-disable-next-line no-plusplus
      this.apex1Counter++
    },

    // setSummaryData(data, type){
    //   let finalData = []
    //   console.log(data[0].department_name)
    //   finalData = data.map(item => ({
    //     filtered_items: item.department_name, cemerlang: item.summary.cemerlang, sangatbaik: item.summary.sangatbaik, baik: item.summary.baik, memuaskan: item.summary.memuaskan, perluperingatan: item.summary.perluperingatan,
    //   }))

    //   return finalData
    // },
  },
}
</script>

<style scoped>

.show-only-year .vdp-datepicker {
    text-align: center !important;
}

.show-only-year .vdp-datepicker__calendar {
    /* bottom: 2px; */
    background: white;
    font-size: 14px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.show-only-year .vdp-datepicker__calendar .cell.selected {
    background: #ff0000;
    border-radius: 6px;
}

.show-only-year .vdp-datepicker__calendar .cell:hover {
    border: 1px solid #ff0000 !important;
    border-radius: 6px;
}
.year-custom {
  background: #fff;
  border-radius: 6px;
  padding-left: 12px;
  border: none;
  height: 53px;
  font-size: 19px;
  color: grey;
  border: 1px solid #d8d6de;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
